import { Input } from '@nextui-org/react';
import React, { Fragment } from 'react';
import { SIGN_UP_ERRORS } from './signUpErrors';

const SignUpForm = ({
    email,
    onSetEmail,
    disableEmail,
    password,
    onSetPassword,
    confirmPassword,
    onSetConfirmPassword,
    onSetGuest,
    onSetPro,
    plan,
    proCycle,
    onSetMonthly,
    onSetAnnual,
    error,
    minimal
}) => {

    return (
        <div className='max-h-[80vh] overflow-scroll'>
            <div className='px-4'>
                <div className="flex flex-col gap-2 pb-1 mt-1">
                    {error && (
                        <div className='bg-error text-sm text-center rounded-lg font-semibold py-2'>
                            {SIGN_UP_ERRORS[error]}
                        </div>
                    )}
                    <Input
                        id='email-input'
                        aria-label="Email"
                        placeholder="Email"
                        disabled={disableEmail}
                        className={disableEmail ? "bg-offwhite border-2 rounded-lg border-gray-300" : "border-2 rounded-lg border-gray-200" }
                        classNames={{
                            input: disableEmail ? 'text-lg sm:text-sm font-medium' : 'text-lg sm:text-sm'
                        }} 
                        value={email}
                        onValueChange={onSetEmail}
                    />
                    <Input 
                        id='pw-input1'
                        aria-label="Password"
                        type='password' 
                        placeholder="Password"
                        className="border-2 rounded-lg border-gray-200" 
                        classNames={{
                            input: 'text-lg sm:text-sm'
                        }} 
                        value={password}
                        onValueChange={onSetPassword}
                    />
                    <Input 
                        id='pw-input2'
                        aria-label="Confirm password"
                        type='password' 
                        placeholder="Confirm password"
                        className="border-2 rounded-lg border-gray-200" 
                        classNames={{
                            input: 'text-lg sm:text-sm'
                        }} 
                        value={confirmPassword}
                        onValueChange={onSetConfirmPassword}
                    />
                </div>

                {/* {!minimal && (
                    <div className="flex gap-1.5 mt-4 mb-4">
                        <div 
                            className={plan === 'GUEST' ? "cursor-pointer flex-grow px-4 pt-2 pb-3 w-1/2 border-2 rounded-lg border-gray-500 shadow-lg" : "cursor-pointer flex-grow px-4 pt-2 pb-3 w-1/2 border-2 rounded-lg border-gray-200 hover:border-gray-300"}
                            onClick={onSetGuest}
                        >
                            <p className="font-semibold">Guest</p>
                            <div className="flex mb-1 -ml-0.5">
                                <span className={plan === 'GUEST' ? "bg-mid-grey text-white px-2 py-0.5 text-xs rounded-lg font-medium border border-transparent" : "bg-gray-200 px-2 py-0.5 text-xs rounded-lg font-medium border border-transparent"}>
                                    Free
                                </span>
                            </div>
                            <p className="text-xs leading-tight">
                                Core access to follow companies, institutions and government officials
                            </p>
                        </div>
                        <div 
                            className={plan === 'PRO' ? "cursor-pointer flex-grow px-4 pt-2 pb-3 w-1/2 border-2 rounded-lg border-gray-500 shadow-lg" : "cursor-pointer flex-grow px-4 pt-2 pb-3 w-1/2 border-2 rounded-lg border-gray-200 hover:border-gray-300"}
                            onClick={onSetPro}
                        >                        
                            <p className="font-semibold">Pro</p>
                            <div className="flex gap-1 mb-1 -ml-0.5">
                                {plan === 'GUEST' && (
                                    <Fragment>
                                        <p 
                                            className="bg-gray-200 px-2 py-0.5 text-xs rounded-lg font-medium border border-transparent"
                                            onClick={onSetMonthly}
                                        >
                                            $7/month
                                        </p>
                                        <p 
                                            className="bg-gray-200 px-2 py-0.5 text-xs rounded-lg font-medium border border-transparent"
                                            onClick={onSetAnnual}
                                        >
                                            $70/year
                                        </p>
                                    </Fragment>
                                )}
                                {plan === 'PRO' && (
                                    <Fragment>
                                        <p 
                                            className={proCycle === 'MONTHLY' ? "bg-mid-grey text-white px-2 py-0.5 text-xs rounded-lg font-medium border border-transparent" : "border border-gray-300 px-2 py-0.5 text-xs rounded-lg font-medium" }
                                            onClick={onSetMonthly}
                                        >
                                            $7/month
                                        </p>
                                        <p 
                                            className={proCycle === 'ANNUAL' ? "bg-mid-grey text-white px-2 py-0.5 text-xs rounded-lg font-medium border border-transparent" : "border border-gray-300 px-2 py-0.5 text-xs rounded-lg font-medium" }
                                            onClick={onSetAnnual}
                                        >
                                            $70/year
                                        </p>
                                    </Fragment>
                                )}
                            </div>
                            <p className="text-xs">
                                Full access to lobbyist client lists
                            </p>
                        </div>
                    </div>
                )} */}
            </div>
        </div>
    )
}

export default SignUpForm;