import { onlyUnique } from "./onlyUnique";
import { DateTime } from "luxon";

export const getMeetingsByDate = (meetings) => {
    
    const uniqueDates = meetings.map(meeting => meeting.date).filter(onlyUnique);
    let datesWithMeetings = [];
    
    uniqueDates.forEach(date => {
        const dateMeetings = meetings.filter(m => m.date === date);
        datesWithMeetings.push({
            date: date,
            meetings: dateMeetings
        });
    });

    return datesWithMeetings;

}

export const getFormattedDate = (date, format = 'DATE_MED') => {
    const dateObj = DateTime.fromISO(date).plus({ days: 1 });
    if (format === 'MMM d') {
        return dateObj.toFormat('MMM d');
    }
    return dateObj.toLocaleString(DateTime[format]);
}

export const getEntityPathFromType = (entity) => {
    let entityType;
    switch (entity.entityType) {
        case 'PUBLIC_SERVANT': entityType = 'public-servant'; break;
        case 'LOBBYIST': entityType = 'lobbyist'; break;
        case 'COMPANY': entityType = 'company'; break;
        case 'INSTITUTION': entityType = 'institution'; break;
        case 'CONSULTING_FIRM': 
        default: entityType = 'consulting-firm';
    }
    return entityType;
}

export const escapeSelector = (id) => {
    return CSS.escape(id);
}

export const MAIN_BUTTON_CLASSES = `font-medium w-2/3 md:w-auto bg-mid-grey text-white rounded-xl sm:rounded-lg`;
export const PAGE_H2 = "text-lg font-bold mb-3 w-2/3 sm:w-full leading-tight";

