import { faArrowRight, faCaretRight, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Input, Modal, ModalBody, ModalContent, Spinner } from '@nextui-org/react';
import React, { useState } from 'react';
import { isNewsletterModalClosed, setNewsletterModalClosed, setNewsletterSubscribed } from '../../utils/newsletterUtils';
import { useMutation } from '@apollo/client';
import NEWSLETTER_SIGN_UP from './mutations/newsletterSignUp';
import validator from 'validator';

const OptionalEmailOverlay = ({
    children
}) => {

    const MODAL_STATE = isNewsletterModalClosed();
    console.log('modal state', MODAL_STATE);
    const [modalIsOpen, toggleModal] = useState(!isNewsletterModalClosed());
    const onCloseModal = () => {
        setNewsletterModalClosed()
        toggleModal(false);
    }
    const onOpenMoal = () => toggleModal(true);

    const [emailInput, setEmailInput] = useState('')
    const onEmailChange = (e) => setEmailInput(e.target.value);
    const isEmail = validator.isEmail(emailInput);

    const [newsletterSignUp, { loading, error, data}] = useMutation(NEWSLETTER_SIGN_UP);

    const onSignUp = (e) => {
        e.preventDefault();
        newsletterSignUp({
            variables: {
                email: emailInput
            },
            onCompleted: () => {
                setNewsletterSubscribed(emailInput);
                toggleModal(false);
            }
        })
    }

    return children;

    return (
        <div className='relative'>
            {/* <div 
                className='hidden sm:flex fixed top-0 w-full h-screen backdrop-blur flex justify-center h-20 z-40'
                style={{
                    background: 'rgb(179 167 144 / 30%)'
                }}
            >
                <div className='h-screen flex flex-col justify-center sm:w-96'>
                    <div className='bg-white shadow-lg px-10 py-8 rounded-md'>
                        
                       
                    </div>
                  
                </div>
            </div> */}
            <Modal 
                isOpen={modalIsOpen}
                onClose={onCloseModal}
                placement='top-center'
                classNames={{
                    base: 'mx-4 mt-4',
                    backdrop: 'modal-bg'
                }}
            >
                <ModalContent className='sm:w-96 px-2 sm:px-4 py-5'>
                    <ModalBody className='text-black'>
                        <div>
                            <p className='font-bold tracking-tight text-3xl sm:text-2xl sm:text-left'>
                                Lobby Report
                            </p>
                            <p className='text-2xl sm:text-xl leading-tight mt-1 sm:text-left'>The fastest way to follow Canadian lobbying</p>
                            <div className='flex flex-col gap-2 mt-6 sm:mt-4'>
                                <form onSubmit={onSignUp}>

                                    <div className='flex items-center'>
                                            <Input
                                                // variant='bordered'
                                                className='border-2 border-offwhite rounded-l-lg text-sm'
                                                placeholder='Enter your email'
                                                value={emailInput}
                                                onChange={onEmailChange}

                                            />
                                            <button 
                                                className='font-medium relative rounded-l-none rounded-r-lg px-4 text-sm border-2 border-offwhite hover:border-offwhite-hover bg-light-gray hover:bg-light-gray-hover'
                                                disabled={!isEmail}
                                                style={{
                                                    marginLeft: -3,
                                                    height: 44,
                                                    zIndex: 2
                                                }}
                                                type='submit'
                                            >
                                                {!loading && <span>Subscribe</span>}
                                                {loading && <span>Sending...</span>}
                                            </button>
                                        
                                    </div>
                                </form>


                                <button
                                    className='bg-transparent opacity-50 font-normal hover:opacity-80 text-sm'
                                    onClick={onCloseModal}
                                >
                                    Let me try it first
                                    <FontAwesomeIcon
                                        icon={faArrowRight}
                                        size='sm'
                                        className='ml-2'
                                    />
                                </button>
                            </div>
                        </div>

                    </ModalBody>
                </ModalContent>
            </Modal>
            <div>
                {children}
            </div>
        </div>
    )
}

export default OptionalEmailOverlay;