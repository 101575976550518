import validator from "validator";

const TOKEN_KEY = "__lobby-report-newsletter-modal-timestamp__";
const TOKEN_EXPIRY_HOURS = 24;

/**
 * Sets the local storage token to indicate the modal is closed.
 * The token is timestamped for expiry checking.
 */
export function setNewsletterModalClosed() {
    const expiryTimestamp = Date.now() + TOKEN_EXPIRY_HOURS * 60 * 60 * 1000;
    localStorage.setItem(TOKEN_KEY, expiryTimestamp);
}

export function setNewsletterSubscribed(email) {
    localStorage.setItem(TOKEN_KEY, email);
}

/**
 * Checks if the modal should be displayed.
 * Returns `true` if the modal can be shown, `false` if it should remain hidden.
 */
export function isNewsletterModalClosed() {
    const storedTimestamp = localStorage.getItem(TOKEN_KEY);
    if (!storedTimestamp) return false; // Modal should be shown if no token exists

    if (validator.isEmail(storedTimestamp)) return true;

    const currentTime = Date.now();
    return currentTime < parseInt(storedTimestamp, 10);
}